<template>
    <div class="mister">
        <div class="uk-card uk-card-default uk-card-body ">
            <h1 class="uk-card-title">
                <slot name="pageTitle">{{ translate('sgirot.project.createTitle') }}</slot>
            </h1>
            <div class="page-content">


                <crud-create
                        :initialItemData="initialItemData"
                        :formProps="{fieldWrapperClass : 'uk-flex uk-flex-stretch uk-flex-wrap uk-gap-m flex-break-large-start'}"
                        :formConfig="formConfig"
                        :formSource="entityType+'/create'"
                        itemSource=""
                        :subject="entityType"
                        :redirect="{name: 'index'}"
                        ref="crudCreate"
                        @form:changed="formChangeHandler"
                ></crud-create>
            </div>
        </div>

    </div>
</template>

<script>

import CrudCreate from '@/client/applications/sgirot/components/crud/Create.vue';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import {useStore} from "vuex";
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';

export default {

    setup (props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());

        let {meta} =  useMeta({
                            title: useI18n().t('sgirot.expert.createTitle')
                        }).meta;
        return {asyncOps, asyncOpsReady, asyncStatus, meta};
    },
    props     : {},
    components: {CrudCreate},
    layout    : 'dashboard',
    middleware: {
        sgirotUser: {
            userTypes: [1]
        }
    },

    data() {
        return {
            entityType: 'project',
            expertCommissions : {},
            asyncData              : {
                expertCommissions   : 'expert/commission-list'
            },
        }
    },
    computed : {
        initialItemData() {
            let initialData = {
                status: 1,
                percentCommission: this.$store.getters['user/profile'].defaultCommissionPercent,
            };
            if ( this.$store.getters['user/profile'].isSuperAdmin) {

            }

            if (this.$store.getters['user/profile'].isExpert ) { // todo : assign expert comission
                initialData.expertId = this.$store.getters['user/profile'].expertId;
            }

            return initialData
        },
        formConfig() {
            let conf = {
                fields: {
                    expertId: {
                        name: 'expertId',
                        type: 'Select2',
                        label : 'sgirot.project.fields.expertName',
                        placeholder : 'sgirot.project.fields.expertNamePlaceholder',
                        itemComponent: 'iconItem',
                        asyncListSource: 'expert/simple-list',
                        asyncListLive: false,
                        asyncDataTextFilterParamName: 'name',
                        multiple: false,
                        validation: {
                            'required': {},
                        },
                        group: 'group3',
                    },
                    clientName : {
                        name: 'clientName',
                        type: 'text',
                        label: 'sgirot.project.fields.clientName',
                        placeholder : 'sgirot.project.fields.clientNamePlaceholder',
                        validation : {},
                        group: 'group1',
                    },
                    address : {
                        name: 'address',
                        type: 'text',
                        label: 'sgirot.project.fields.address',
                        placeholder : 'sgirot.project.fields.addressPlaceholder',
                        validation : {},
                        group: 'group1',
                    },
                    city : {
                        name: 'city',
                        type: 'text',
                        label: 'sgirot.project.fields.city',
                        placeholder : 'sgirot.project.fields.cityPlaceholder',
                        validation : {},
                        group: 'group1',
                    },
                    clientPhoneNumber : {
                        name: 'clientPhoneNumber',
                        type: 'text',
                        label: 'sgirot.project.fields.clientPhoneNumber',
                        placeholder : 'sgirot.project.fields.clientPhoneNumberPlaceholder',
                        validation : {},
                        group: 'group1',
                    },
                    sourceId: {
                        name: 'sourceId',
                        type: 'Select2',
                        label : 'sgirot.project.fields.source',
                        placeholder : 'sgirot.project.fields.sourcePlaceholder',
                        itemComponent: 'iconItem',
                        asyncListSource: 'source/simple-list',
                        asyncListLive: false,
                        asyncDataTextFilterParamName: 'name',
                        multiple: false,
                        validation: {

                        },
                        group: 'group3',
                    },

                    description: {
                        name: 'description',
                        type: 'text',
                        label: 'sgirot.project.fields.description',
                        placeholder : 'sgirot.project.fields.descriptionPlaceholder',
                        validation : {},
                        group: 'group2',
                    },
                    dueDate: {
                        name: 'dueDate',
                        type: 'sgirotDate' +
                            '',
                        label: 'sgirot.project.fields.dueDate',
                        placeholder : 'sgirot.project.fields.dueDatePlaceholder',
                        validation : {},
                        group: 'group2',
                    },
                    dueTime: {
                        name: 'dueTime',
                        type: 'text',
                        label: 'sgirot.project.fields.dueTime',
                        placeholder : 'sgirot.project.fields.dueTimePlaceholder',
                        validation : {},
                        group: 'group2',
                    },

                    price: {
                        name: 'price',
                        type: 'number',
                        label: 'sgirot.project.fields.price',
                        placeholder : 'sgirot.project.fields.pricePlaceholder',
                        validation : {
                            required: {},
                            integer: {}
                        },
                        group: 'group3',
                    },
                    percentCommission: {
                        name: 'percentCommission',
                        type: 'number',
                        label: 'sgirot.project.fields.percentCommission',
                        placeholder : 'sgirot.project.fields.percentCommissionPlaceholder',
                        validation : {
                            integer: {}
                        },
                        group: 'group3',
                    },
                    status: {
                        name: 'status',
                        type: 'Select2',
                        label : 'sgirot.project.fields.status',
                        placeholder : 'sgirot.project.fields.statusPlaceholder',
                        itemComponent: 'iconItem',
                        asyncListSource: 'project/status/simple-list',
                        asyncListLive: false,
                        asyncDataTextFilterParamName: 'name',
                        multiple: false,
                        validation: {
                            'required': {},
                        },
                        group: 'group3',
                    },
                },
                groups : {
                    group1: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.project.fields.clientFieldsetTitle',
                            icon : 'info',
                            class: 'uk-width-xlarge uk-margin-top',
                            style: 'flex: 30%;min-width: 300px;'
                        }
                    },
                    group2: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.project.fields.projectFieldsetTitle',
                            icon : 'cog',
                            class: 'uk-width-xlarge uk-margin-top',
                            style: 'flex: 30%;min-width: 300px;'
                        }
                    },
                    group3: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.project.fields.paymentFieldsetTitle',
                            icon : 'credit-card',
                            class: 'uk-width-xlarge uk-margin-top',
                            style: 'flex: 30%;min-width: 300px;'
                        }
                    },
                },
                buttons: {},
                context: 'edit',
            };

            if ( this.$store.getters['user/profile'].isSuperAdmin) {
                return conf;
            }

            delete conf.fields.sourceId;
            conf.fields.expertId = {
                name: 'expertId',
                label : 'todo',
                type: 'hidden'
            }

            return conf;
        }
    },

    methods   : {
        formChangeHandler (data) {
            if (data.changedKey === 'expertId' && this.expertCommissions.hasOwnProperty(data.changeValueTo)) {
                this.$refs.crudCreate.setFormValue('percentCommission', this.expertCommissions[data.changeValueTo])
            }
        }
    }
}
</script>
